//
// map-gmp.js
// Theme module
//
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
// import * as esri from 'esri-leaflet'

const maps = document.querySelectorAll('[data-map]');
// const accessToken = 'pk.eyJ1Ijoic2NvdGhpcyIsImEiOiJjaWp1Y2ltYmUwMDBicmJrdDQ4ZDBkaGN4In0.sbihZCZJ56-fsFNKHXF8YQ';

maps.forEach((map) => {
  const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

  const defaultOptions = {
    zoomControl: false
  };
  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  // Get access token
  // mapboxgl.accessToken = accessToken;

  // Init map
  // new mapboxgl.Map(options);

  delete L.Icon.Default.prototype._getIconUrl
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  })

  // console.log(options)
  const newMap = new L.map(map, options)

  const googleStreets = new L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
    maxZoom: 30,
    subdomains:['mt0','mt1','mt2','mt3']
  })

  newMap.addLayer(googleStreets)

  // const mapType = 'Streets'
  // const labelsType = 'ImageryLabels'
  // const esriMapLayer = esri.basemapLayer(mapType)
  // const esriLabelsLayer = esri.basemapLayer(labelsType)
  // newMap.addLayer(esriMapLayer)
  // newMap.addLayer(esriLabelsLayer)

  // See here for basemap options:
  // http://esri.github.io/esri-leaflet/api-reference/layers/vector-basemap.html#vector-basemaps
  // L.esri.Vector.vectorBasemapLayer('ArcGIS:Streets', {
  //     apikey: apiKey // Replace with your API key - https://developers.arcgis.com
  // }).addTo(map);

  L.marker(elementOptions.center).addTo(newMap)


});
